a.banner_slider_item {
  position: relative;

  &_timer {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
    border-radius: 16px;
    background: #131318;
  }
  &_link {
    position: absolute;
    bottom: 72px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px 32px;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    text-transform: uppercase;
    width: fit-content;
    text-wrap: nowrap;

    @media (max-width: 1280px) {
      padding: 8px 12px;
      font-size: 16px;
      border-radius: 4px;
      bottom: 52px;
    }
  }
}

.banner_slider_container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.banner_slider {
  width: 100%;
  height: auto;
  //margin-bottom: 32px;

  @media (max-width: 768px) {
    height: 246px;
  }
  .swiper-pagination {
    position: absolute;
    bottom: 22px !important;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.banner_slide {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
}

.banner_image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.banner_container {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.banner_slider {
  width: 100%;
  position: relative;
}

.banner_slider_item {
  position: relative;
  height: 468px !important;

  @media (max-width: 1620px) {
    height: 336px !important;
  }

  @media (max-width: 1280px) {
    height: auto !important;
  }

  @media (max-width: 768px) {
    img {
      height: 100% !important;
      object-fit: cover;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.banner_slider_item_timer {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: #131318;
  color: white;
  padding: 8px 16px;
  border-radius: 16px;
  z-index: 2;
  font-weight: 500;
  font-size: 24px;
  line-height: 117%;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    padding: 4px 8px;
    border-radius: 8px;
  }
}

.swiper-pagination {
  position: absolute;
  bottom: 22px !important;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.banner_pagination_bullet {
  width: 12px;
  height: 12px;
  background: #363744;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.banner_pagination_bullet_active {
  background: #b3b5c6;
  width: 12px;
  height: 12px;
}
