.home_filters_mob {
  height: 48px;
  background: #202028;
  overflow: hidden;
  padding: 8px 16px;
  margin: 0 -24px 16px -24px;
  align-items: center;
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    margin: 0 -16px 16px -16px;
  }

  @media (max-width: 768px) {
    position: sticky;
    top: 59px;
    left: 0;
    width: 100vw;
    z-index: 7;
    margin-bottom: 0;
  }

  &_settings {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_categories {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 10px;
    margin-left: 8px;
    position: relative;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      border-radius: 1000px;
      width: 2px;
      height: 32px;
      transform: translateY(-50%);
      background: #131318;
    }

    button {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.11111;
      text-transform: uppercase;
      color: #47485d;
      flex-shrink: 0;

      &.active,
      &:hover {
        color: #fff;
      }
    }
  }

  &_deactivated {
    opacity: 0.5;
    pointer-events: none;
  }
}

.filter_modal {
  height: 100dvh;
  max-height: 100dvh !important;
  border: 0 !important;
  border-radius: 0 !important;
}
