.mobile_filter {
  width: 100%;

  &_title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 16px;
    color: #ffffff;
  }

  &_content {
    width: 100%;
  }
}

.mobile_filter_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mobile_filter_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #363744;

  label {
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: #ffffff;
  }

  .ant-input {
    background: #363744;
    color: #8A8CA6;
  }

  .ant-input-number {
    background-color: #363744;
  }

  .ant-input-number-input {
    background: #363744;
  }

  .ant-input-affix-wrapper,
  .ant-input-number-affix-wrapper {
    height: 100%;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    color: #8A8CA6;
    background: #363744;
    border: 0;

    input {
      &::placeholder {
        color: #8A8CA6;
      }
    }
    .ant-input-number-prefix {
      color: #FFB800;
    }
  }
  .ant-input-number-affix-wrapper {
    width: 116px;
  }
  .ant-input-number-input {
    color: #8A8CA6;
    font-size: 16px;
  }
}

.mobile_filter_item_input {
  border-radius: 8px !important;
  background-color: #363744 !important;

  //На время отключаем поиск
  opacity: 0.48;
  pointer-events: none;

  .ant-input {
    background: #363744;
    color: #8A8CA6;
  }

  .ant-input-number {
    background-color: #363744;
  }

  .ant-input-number-input {
    background: #363744;
  }

  .ant-input-affix-wrapper,
  .ant-input-number-affix-wrapper {
    height: 100%;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    color: #8A8CA6;
    background: #363744;
    border: 0;

    input {
      &::placeholder {
        color: #8A8CA6;
      }
    }
    .ant-input-number-prefix {
      color: #FFB800;
    }
  }
  .ant-input-number-affix-wrapper {
    width: 116px;
  }
  .ant-input-number-input {
    color: #8A8CA6;
    font-size: 16px;
  }
}

.mobile_filter_item_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  background-color: #363744;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #8a8ca6;
  gap: 8px;

  & > span {
    margin-right: auto;
  }
}

.home_filters_item_price {
  display: flex;
  justify-content: space-between;
  gap: 38px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 2px;
    background: #363744;
  }

  position: relative;
  height: 48px;

  .ant-input-affix-wrapper,
  .ant-input-number-affix-wrapper {
    height: 100%;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    color: #8a8ca6;
    background: #363744;
    border: 0;

    input {
      &::placeholder {
        color: #8a8ca6;
      }
    }
    .ant-input-number-prefix {
      color: #ffb800;
    }
  }
  .ant-input-number-affix-wrapper {
    width: calc(50% - 19px) !important;
  }
  .ant-input-number-input {
    color: #8a8ca6;
    font-size: 16px;
  }
}

.mobile_filter_btn {
  min-height: 44px;
  border-radius: 8px;
}
