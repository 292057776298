@import '../abstracts/extends';

.cases-all .case-row {
  margin-bottom: 5px;
  margin-top: -5px;
}

.cases-all {
  .case-row-title {
    padding: 0 30px 5px;
    text-align: center;
    margin-bottom: 16px;
    position: relative;
    top: 2px;
  
    @media (max-width: 1366px) {
      margin-bottom: 16px;
    }
    @media (max-width: 480px) {
      margin-bottom: 25px;
    }
  }
  .case-row-small {
    .case-row-title {
      padding: 0 30px 0px;
      margin-bottom: 20px;
    }
  }
}


.cases-all {
  //padding-top: 53px;
  position: relative;
  z-index: 4;

  @media (max-width: 1280px) {
    overflow: hidden;
  }
}

.cases-all .case-row-title-name {
  position: relative;
  line-height: 50px;
  margin-top: -5px;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 900;

  @media(max-width: 1024px) {
    font-size: 20px;
    line-height: 20px;
    height: 50px;
  }

  img {
    margin-right: 15px;
    display: none;
  }
}

.case-item-name,
.case-row-title {
  line-height: 24px;
  text-transform: uppercase;
}
.case-item-name {
  min-height: 35px;
}

.cases {
  margin: 0 auto;

  @media (max-width: 1440px) {
    overflow: hidden;
  }

  &.cases-all {
    margin-top: 16px;
    overflow: hidden;
  }
}

.case-row-title {
  color: #fff;
  font-size: 24px;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

.cases-all .row {
  //margin-top: 10px;

  // & > :last-child {
  //   position: relative;
  //   //background: rgba(235, 75, 75, 0.12);
  //   box-shadow: inset 0px 2px 0px rgba(255, 198, 0, 0.2);
  //   padding-bottom: 100px;

  //   @media(max-width: 1024px) {
  //     padding-bottom: 32px;
  //   }

  //   &:after {
  //     @extend %before-after;
  //     top: calc(100% - 141px);
  //     background: linear-gradient(180deg, rgba(19, 7, 44, 0) 0%, #14082C 100%);
  //     height: 141px;
  //     width: 100%;
  //   }
  // }
}

.cases-all .case-row-title-name:after {
  left: auto;
  right: -46px;
}

.cases-all .case-row-title:before {
  content: ' ';
  width: 570px;
  height: 191px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
}

.cases-all .case-row-title-name {
  color: rgba(17, 0, 49, 0.72);

  .mask {
    position: absolute;
    background: #fff;
    -webkit-mask: url(../images/bg-center-mask.svg) repeat-x 50% 50%;
    width: 100%;
    height: 100%;
    display: block;
  }

  .name {
    position: relative;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -46px;
    display: block;
    z-index: 1;
    width: 47px;
    height: 50px;
    background: #fff;
    -webkit-mask: url(../images/bg-left-mask.svg) no-repeat 100% 50%;
  }

  &:after {
    left: auto;
    right: -46px;
    -webkit-mask: url(../images/bg-right-mask.svg) no-repeat 0% 50%;
  }
}

.cases-all {
  .case-row[data-rar="b0c3d9"] {
    background: rgba(176, 195, 217, 0.12);
    box-shadow: inset 0px 2px 0px rgba(176, 195, 217, 0.2);

    .case-row-title-name {
      .mask,
      &:before,
      &:after {
        background-color: #b0c3d9;
      }
    }
  }

  .case-row[data-rar="5e98d9"] {
    background: rgba(94, 152, 217, 0.12);
    box-shadow: inset 0px 2px 0px rgba(94, 152, 217, 0.2);

    .case-row-title-name {
      .mask,
      &:before,
      &:after {
        background-color: #5e98d9;
      }
    }
  }

  .case-row[data-rar="4b69ff"] {
    background: rgba(75, 105, 255, 0.12);
    box-shadow: inset 0px 2px 0px rgba(75, 105, 255, 0.2);

    .case-row-title-name {
      .mask,
      &:before,
      &:after {
        background-color: #4b69ff;
      }
    }
  }

  .case-row[data-rar="8847ff"] {
    background: rgba(136, 71, 255, 0.12);
    box-shadow: inset 0px 2px 0px rgba(136, 71, 255, 0.2);

    .case-row-title-name {
      .mask,
      &:before,
      &:after {
        background-color: #8847ff;
      }
    }
  }

  .case-row[data-rar="d32ce6"] {
    background: rgba(211, 44, 230, 0.12);
    box-shadow: inset 0px 2px 0px rgba(211, 44, 230, 0.2);

    .case-row-title-name {
      .mask,
      &:before,
      &:after {
        background-color: #d32ce6;
      }
    }
  }

  .case-row[data-rar="eb4b4b"] {
    background: rgba(235, 75, 75, 0.12);
    box-shadow: inset 0px 2px 0px rgba(235, 75, 75, 0.2);

    .case-row-title-name {
      .mask,
      &:before,
      &:after {
        background-color: #eb4b4b;
      }
    }
  }

  .case-row[data-rar="e4ae39"] {
    background: rgba(228, 174, 57, 0.12);
    box-shadow: inset 0px 2px 0px rgba(228, 174, 57, 0.2);

    .case-row-title-name {
      .mask,
      &:before,
      &:after {
        background-color: #e4ae39;
      }
    }
  }

  .case-row.pattern {
    background-image: url(../images/case-row-pattern.png);
  }
}

.case-row-title-sec {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(213, 185, 255, 0.5);
  text-transform: none;
  margin-top: 20px;
}

.open-case.old-sale {
  background-image: linear-gradient(to right, #330351, #1d0457);
  border: none;
  box-shadow: none !important;
  min-width: 70px !important;
  margin-right: 5px;
  position: relative;
  overflow: visible;
  line-height: 28px;
  height: 28px;
  vertical-align: top;
  margin-top: 5px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-align: center;
  color: rgba(213, 185, 255, 0.5);
  padding: 0 12px;

  @media(max-width: 992px) {
    margin-bottom: 10px;
  }
  @media(max-width: 768px) {
    margin-right: 12px;
    font-size: 16px;
  }
  @media(max-width: 359px) {
    margin-right: 4px;
    padding: 0 9px;
    min-width: auto !important;
  }
}

.cases-all .open-case.old-sale .fa {
  color: rgba(255, 255, 255, 0.5);
}

.open-case.old-sale {
  &:before {
    @extend %before-after;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    background-image: linear-gradient(to right, #330351, #1d0457);
    display: block;
    width: calc(100% - 24px);
    z-index: 2;
    margin-top: 2px;
  }

  &:after {
    @extend %before-after;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    box-shadow: 0 0 3px 0 #ff6262;
    background-color: #ffc2c3;
    display: block;
    width: calc(100% - 24px);
    z-index: 2;
  }
}

.case-cost .fa {
  margin-left: 5px;
}

// case
.case-row {
  position: relative;
}

.case-item .load {
  text-decoration: none;

  .img-default {
    opacity: 0;
  }
}

.case-cost-battle-pass {
  background: linear-gradient(90deg, #26003e 0%, #230170 100%);
  box-shadow: 0 0 0 4px rgb(40 4 161 / 24%);
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(213, 185, 255, 0.68);
  padding: 0 4px 0 8px;
  margin: 0 12px;

  svg {
    margin-left: 4px;
  }
}
