.game_mode_list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;

  @media (max-width: 1280px) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  @media (max-width: 767px) {
    overflow-x: hidden;
    width: 100%;
    gap: 8px;
    margin-bottom: 8px;
  }

  &_wrap {
    display: flex;
    flex-direction: column;

    @media (max-width: 640px) {
      flex-direction: column-reverse;
    }
  }
  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h2 {
      font-weight: 700;
      font-size: 24px;

      @media (max-width: 640px) {
        display: none;
      }
    }
    a {
      width: fit-content;
      padding-left: 16px;

      @media (max-width: 767px) {
        width: 100%;
        border-radius: 8px;
        font-size: 16px;
        padding-left: 16px;
      }

      span {
        margin-right: auto;
      }
    }
  }
  &_title_btn {
    padding: 10px 10px 10px 16px;
    background: #202028 !important;
  }
}
