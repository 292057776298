.modal_select_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 99990; /* Чуть меньше, чем у самой модалки */
  animation: fadeIn 0.3s ease-out forwards;

  &.closing {
    animation: fadeOut 0.3s ease-out forwards;
  }
}

.modal_select {
  position: fixed; /* Позиционируем относительно окна браузера */
  bottom: 0; /* Прикрепляем к нижней части экрана */
  left: 0;
  right: 0;
  background-color: rgba(20, 20, 20, 0.95); /* Добавляем фон */
  padding: 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 99999; /* Высокий z-index для отображения поверх других модалок */
  animation: slideUp 0.3s ease-out forwards;

  &.closing {
    animation: slideDown 0.3s ease-out forwards;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: #ffffff;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 8px;
    list-style-type: none;

    li {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 125%;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
