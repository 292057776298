.text_dropdown {
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  padding-bottom: 24px;
  border-bottom: 2px solid #202028;
  margin-bottom: 24px;

  p {
    margin: 0;
  }

  &_title {
    font-size: 16px;
    margin-bottom: 8px;
  }
  &_button {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    gap: 8px;
    cursor: pointer;
    margin-top: 8px;

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &_content {
    height: 54px;
    overflow: hidden;

    &.active {
      height: auto;
    }
  }
}