.case_list_wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768) {
    //maybe without important
    gap: 16px !important;
  }

  &_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 767px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      text-transform: uppercase;
      text-align: center;
    }
  }
}

.main_cases_list {
  margin-top: 24px;
}
