.game_mode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  padding: 12px 12px 12px 10px;
  min-height: 165px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: #fff;
  // width: calc(20% - 13px);
  flex: 1;
  cursor: pointer;

  @media (max-width: 1280px) {
    width: calc(25% - 12px);
    min-width: calc(25% - 12px);

    &:last-child {
      display: none;
    }
  }
  @media (max-width: 992px) {
    width: calc(33.3% - 13px);
    min-width: calc(33.3% - 13px);
  }
  @media (max-width: 767px) {
    width: calc(50% - 4px);
    min-width: calc(50% - 4px);
    min-height: 100px;
    padding: 8px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .64;
    background: linear-gradient(180deg, rgba(136, 71, 255, 0.3) 0%, rgba(170, 133, 255, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(136, 71, 255, 0.48) 0%, rgba(136, 71, 255, 0) 100%);
    //z-index: -1;
  }
  &_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.11111;
    position: relative;
    z-index: 2;

    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
    }
  }
  &_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.28571;
    max-width: 165px;
    position: relative;
    z-index: 2;

    @media (max-width: 640px) {
      display: none;
    }
  }
  &_count {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px 4px 4px;
    border-radius: 4px;
    backdrop-filter: blur(8px);
    background: rgba(54, 55, 68, 0.32);

    svg {
      margin-bottom: 2px;
    }
  }
  &_image {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.bg-game-1 {
  &::before {
    background: linear-gradient(180deg, rgba(136, 71, 255, 0.3) 0%, rgba(170, 133, 255, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(136, 71, 255, 0.48) 0%, rgba(136, 71, 255, 0) 100%);
  }
}
.bg-game-2 {
  &::before {
    background: linear-gradient(180deg, rgba(75, 105, 255, 0.3) 0%, rgba(117, 150, 255, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(75, 105, 255, 0.48) 0%, rgba(75, 105, 255, 0) 100%);
  }
}
.bg-game-3 {
  &::before {
    background: linear-gradient(180deg, rgba(235, 75, 75, 0.3) 0%, rgba(245, 116, 116, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(235, 75, 75, 0.48) 0%, rgba(235, 75, 75, 0) 100%);
  }
}
.bg-game-4 {
  &::before {
    background: linear-gradient(180deg, rgba(255, 198, 0, 0.3) 0%, rgba(255, 229, 27, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(255, 198, 0, 0.48) 0%, rgba(255, 198, 0, 0) 100%);
  }
}
.bg-game-5 {
  &::before {
    background: linear-gradient(180deg, rgba(176, 195, 217, 0.3) 0%, rgba(211, 221, 234, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(176, 195, 217, 0.48) 0%, rgba(176, 195, 217, 0) 100%);
  }
}