.stats1_wrap {
  padding: 40px 24px 24px 24px;

  @media(max-width: 1024px) {
    padding: 24px 24px 24px 24px;
    margin-bottom: 16px;
  }

  @media(max-width: 1024px) {
    padding: 24px 16px 24px 16px;
  }
  @media(max-width: 992px) {
    padding: 24px 0;
  }
}
.stats1 {
  display: flex;
  justify-content: space-between;

  @media(max-width: 1024px) {
    flex-wrap: wrap;
    gap: 16px;
  }
  &_item {
    display: flex;
    align-items: center;
    gap: 4px;

    @media(max-width: 1024px) {
      width: calc(50% - 8px);
    }
  }
  &_item_content_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.11111;

    @media(max-width: 992px) {
      font-size: 16px;
    }
  }
  &_item_content_label {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    color: #B3B5C6;

    @media(max-width: 992px) {
      font-size: 14px;
    }
  }
}