.case {
  width: calc(20% - 9.6px);
  background: rgba(54, 55, 68, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease;
  position: relative;

  @media (max-width: 1024px) {
    width: calc(25% - 10px);
  }
  @media (max-width: 768px) {
    width: calc(33.3% - 10px);
  }
  @media (max-width: 640px) {
    width: calc(50% - 6px);
    padding-bottom: 8px;
  }
  &:hover {
    background: rgba(54, 55, 68, 0.16);
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 6px;
    margin-bottom: -12px;

    @media (max-width: 767px) {
      width: 160px;
      aspect-ratio: 1/1;
      margin-top: 0;
    }
  }
  &_name {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    margin-bottom: 12px;
    width: calc(100% - 24px);

    @media (max-width: 1024px) {
      font-size: 16px;
    }

    @media (max-width: 767px) {
      margin-bottom: 8px;
      width: calc(100% - 16px);
    }
  }
  &_cost {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    background: #202028;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
    width: calc(100% - 24px);
    position: relative;

    @media (max-width: 1024px) {
      font-size: 16px;
    }

    @media (max-width: 767px) {
      padding: 8px 12px;
      width: calc(100% - 16px);
    }
  }
}

.case_cost_icon {
  position: absolute;
  right: 8px;
  top: -8px;
  display: flex;
  align-items: center;
  gap: 2px;
  color: #ffffff;
  padding: 2px 4px 2px 2px;
  background: #089408;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
}

.case_percent {
  position: absolute;
  left: 16px;
  top: 16px;
  border-radius: 4px;
  padding: 4px 4px 2px;
  background: #131318;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  color: #ffffff;
}
