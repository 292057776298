.giveaways {
    margin-bottom: 32px;
    height: 116px;
    position: relative;
  
    @media(max-width: 1024px) {
      margin: 0 -24px 24px -24px;
    }
    @media(max-width: 992px) {
      margin: 0 -16px 24px -16px;
    }
    &_title {
      align-items: center;
      background: #202028;
      border-radius: 8px;
      border-top: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.25;
      display: flex;
      height: 32px;
      justify-content: center;
      left: -38px;
      line-height: 20px;
      position: absolute;
      text-transform: uppercase;
      top: 43px;
      transform: rotate(-90deg);
      width: 116px;
      z-index: 2;
  
      @media (max-width: 1024px) {
        border-radius: 0px 0px 8px 8px;
        left: -44px;
      }
    }
    &_list {
      display: flex;
      gap: 8px;
      padding-left: 49px;
      position: relative;
      height: 116px;
  
      @media(max-width: 1024px) {
        overflow-x: auto;
        margin-right: -24px;
        width: 100%;
        padding-right: 24px;
      }
    }
  }