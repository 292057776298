.case_list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}
